html {
    background-color: #121820;
}

.info-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.info-container {
    width: max(55vw, 900px);
    height: max(470px, 55vh);
    display: flex;
    flex-direction: row;
    color: white;
}

.info-pane {
    flex: 1 1 0px;
    overflow-y:auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    margin-bottom: 30px;
}

*::-webkit-scrollbar {
    width: 5px;
  }
  
*::-webkit-scrollbar-track {
background: #1b2029
}

*::-webkit-scrollbar-thumb {
background-color: #5b5d5f;
border-radius: 20px;
}

.info-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 2px solid #222d3b;
}

.info-right {
    padding-top: 1vh;
}

#hey-header {
    font-size: max(max(1.7vw, 3.2vh), 32px);
}

#hand {
animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
animation-duration: 2.5s;        /* Change to speed up or slow down */
animation-iteration-count: infinite;  /* Never stop waving :) */
transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
    20% { transform: rotate(-8.0deg) }
    30% { transform: rotate(14.0deg) }
    40% { transform: rotate(-4.0deg) }
    50% { transform: rotate(10.0deg) }
    60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}

#left-desc {
    padding: 0 15%;
    text-align: center;
    font-weight: 400;
    font-size: max(0.99vw, 17px);
}
.profile-pic {
    background-repeat: no-repeat;
    background-size: cover;
    height: max(23.1vh, 200px);
    width: max(23.1vh, 200px);
    border-radius: 50%;
    margin-top: 5%;
}

.experiences {
    display: flex;
    flex-direction: column;
}

.pane-title {
    text-align: center;
    font-size: max(1.7vw, 30px);
}

.vertical-timeline {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
}

.vertical-line {
    width: 3px;
    background: #273344;
    height: 8vh;
}

.company-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.logo-container {
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.x {
    flex: 0.1;
}
.logo {
    border-radius: 25%;
    width: max(13vh, 110px);
    height: max(13vh, 110px);
    background-repeat: no-repeat;
    background-size: cover;
}
.company-description {
    flex: 1;
    padding-top: 2vh;
    margin-left: -2vw;
}

.comp-desc-header {
    margin: 0;
    margin-bottom: 1vh;
}

.comp-name {
    font-size: max(1.1vw, 20px);
}

.comp-pos {
    font-size: max(0.78vw, 15px);
}

.comp-date {
    font-size: max(0.70vw, 13px);
}

#companySpacer {
    height: 7vh;
}

.socials-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.socials-container i {
    font-size: max(3vw, 55px);
    margin-right: max(1.2vw, 20px);
    color:#3d71c0
}

#resumeSocial {
    font-size: max(2.7vw,51px);
    margin-top: 0.2vw
}

#linkedinSocial:hover {
    color: #6263c0;
}

#githubSocial:hover {
    color: lightcoral
}

#resumeSocial:hover {
    color: burlywood;
}