@media only screen and (max-width: 900px) {

    .info-container {
        height: 100vh;
    }
    .info-pane {
        height: 50vh;
        margin-bottom: 20px;
    }
    
    #hey-header {
        font-size: max(3.03vh, 18px);
    }

    .socials-container i {
        font-size: max(4.35vh, 27.5px);
        margin-right: max(1.2vw, 18px);
    }

    #resumeSocial {
        font-size: max(3.9vh,25.5px);
        margin-top: 0.3vh;
    }

    #left-desc {
        padding: 0 15%;
        text-align: center;
        font-weight: 400;
        font-size: max(2vh, 12.5px);
    }

    .profile-pic {
        background-repeat: no-repeat;
        background-size: cover;
        height: max(15vh, 100px);
        width: max(15vh, 100px);
        border-radius: 50%;
    }

    
    .info-container {
        flex-direction: column;
        width: 90%;
    }
    .info-left {
        border-bottom: 2px solid #222d3b;
        border-right: none;
        justify-content: center;
    }

    .pane-title {
        margin-top: 0;
        font-size: 3.72vh;
    }

    .info-right {
        margin-top: 0px;
        padding-top: 0;
    }

    .logo-container {
        margin-left: -35px;
    }

    .logo {
        border-radius: 25%;
        width: max(9.9vh, 67px);
        height: max(9.9vh, 67px);
        background-repeat: no-repeat;
        background-size: cover;
    }
    .company-description {
        flex: 1;
        padding-top: 0;
        margin-left: -40px;
    }
    
    .comp-desc-header {
        margin: 0;
        margin-bottom: 1vh;
    }
    
    .comp-name {
        font-size: max(2.28vh, 14.5px);
    }
    
    .comp-pos {
        font-size: max(1.675vh, 11px);
    }
    
    .comp-date {
        font-size: max(1.555vh, 10.5px);
    }
    
    #companySpacer {
        height: 4.2vh;
    }

}